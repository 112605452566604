import React, { FC, useState, useEffect } from 'react';
import { Button } from '../Shared/Button';

export const Shopify: FC = () => {

    const connectShopify = () => {
	};

	return (
		<>
			<div className="DashboardLeftWrapper">
				<div className="DashboardLeftTitle">
					Emojam is the new digital merch!
				</div>
				<div
					className="DashboardLeftDescription"
					style={{ marginTop: 27, marginBottom: 40 }}>
					Our partnership with Shopify allows you to offer exclusive
					Emojam audio stickers on your merch store to receive a
					monthly subscription fee.{' '}
					<a
						style={{ color: 'black' }}
						href="https://www.emojam.com/shopify"
						target="_blank"
						rel="noopener noreferrer">
						Learn more.
					</a>
				</div>
				<div>
					<Button size="normal" onClick={connectShopify}>
                        connect with Shopify
                    </Button>
				</div>
			</div>
		</>
	);
};
