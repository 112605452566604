import React, { FC, useState, useEffect } from 'react';
import { MainLayout, MainLeftLayout, MainRightLayout } from '../Layouts/Main';
import { Stepper } from '../Shared/Stepper';
import { Input } from '../Shared/Input';
import { Button } from '../Shared/Button';
import { Music } from '.';
import { InputTags } from '../Shared/Input/InputTags';
import { Preview } from '../Preview';
import { Switch } from '../Shared/Switch';
import { Emojam } from '../../../types/api';
import { useDispatch } from 'react-redux';
import { auth } from 'firebase';
import { pushMessage } from '../../actions/messages';
import { CustomModalYoutube } from '../Shared/Modal/ModalYoutube';
import { useHistory } from 'react-router-dom';
import { ReadMore } from '../Shared/ReadMore';

type Props = {
	fileSrc: string;
	music: Music;
	handleSubmit: (
		tile: string,
		source: string,
		tags: string[],
		type: 'emojam' | 'video' | 'nft',
		shopify: any,
		visibility: 'private' | 'public' | 'members' 
	) => Promise<void>;
};

export const Details: FC<Props> = ({ fileSrc, music, handleSubmit }) => {
	const [tags, setTags] = useState<string[]>([]);
	const [playing, setPlaying] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [title, setTitle] = useState(music.title);
	const [source, setSource] = useState(music.artist);
	const [visibility, setVisibility] = useState<
		'public' | 'private' | 'members' 
	>('public');
	const [type, setNftType] = useState<any>('emojam');
	const [shopify, setShopify] = useState<any>(0);
	const history = useHistory();
	const [userInfo, setUserInfo] = useState<any>(null);
	const dispatch = useDispatch();
	const user = auth().currentUser as firebase.User;
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		user.getIdTokenResult()
			.then(res => {
				setUserInfo(res);
				console.log(res,"resss");				
			})
			.catch(err => {
				dispatch(pushMessage(err.message, 'error'));
			});
	}, []);

	return (
		<MainLayout>
			<MainLeftLayout>
				<div className="DashboardLeftWrapper">
					<div className="DashboardLeftNumber">4</div>
					<div className="DashboardLeftContent">
						<div className="DashboardLeftTitle">
							<span>
								add
								<br />
								details.
							</span>
						</div>
						<div className="DashboardLeftDescription">
							<ReadMore>
								Metadata helps users to find your content.{' '}
								<a
									href="https://www.emojam.com/tips-for-tagging-content"
									target="_blank"
									rel="noopener noreferrer">
									Tips for tagging content
								</a>
								.
								<br />
								<br />
								By clicking “upload” you agree to our{' '}
								<a
									href="https://www.emojam.com/terms"
									target="_blank"
									rel="noopener noreferrer">
									terms of service
								</a>
								,{' '}
								<a
									href="https://www.emojam.com/community-guidelines"
									target="_blank"
									rel="noopener noreferrer">
									community guidelines
								</a>{' '}
								and our{' '}
								<a
									href="https://www.emojam.com/privacy"
									target="_blank"
									rel="noopener noreferrer">
									privacy policy
								</a>
								.
							</ReadMore>
						</div>
					</div>
				</div>
			</MainLeftLayout>
			<MainRightLayout>
				<div className="flex space-between wrap-reverse align-center top-25">
					<div className="CreatePreviewWrapper">
						<Preview
							src={fileSrc}
							emojam={
								{
									audios: [music.url as any],
								} as Emojam
							}
							playing={playing}
							onToggle={() => setPlaying(!playing)}
							dashed
						/>
					</div>
					<Stepper
						steps={[
							{ value: 1, link: '/create/1' },
							{ value: 2, link: '/create/2' },
							{ value: 3, link: '/create/2' },
							{ value: 4 },
						]}
						currentIndex={3}
					/>
				</div>
				<div className="DetailsForm">
					<div className="flex align-center justify-end direction-column">
						<>
							<div className="SwitchWrapper">
								<label
									style={{
										letterSpacing: '0.05em',
										fontSize: 10,
										marginRight: 20,
									}}
									htmlFor={'public'}>
									public
								</label>
								<Switch
									id="public"
									disabled={type === 'nft' || shopify === 1 ? true : false}
									checked={
										visibility === 'public' ||
										visibility === 'members'
									}
									onChange={() => {
										setVisibility(
											visibility === 'public' ||
												visibility === 'members'
												? 'private'
												: 'public'
										);
									}}
								/>
							</div>
							<div className="SwitchWrapper">
								<label
									style={{
										letterSpacing: '0.05em',
										fontSize: 10,
										marginRight: 20,
									}}
									htmlFor={'members'}>
									for YouTube members only
								</label>
								<Switch
									id={'members'}
									disabled={type === 'nft' || shopify === 1 ? true : false}
									checked={visibility === 'members'}
									onChange={() => {
										if (
											userInfo?.claims?.youtube?.type ===
											'youtube'
										) {
											if (
												visibility === 'private' ||
												visibility === 'public'
											) {
												setVisibility('members');
											} else {
												setVisibility('public');
											}
										} else {
											setIsOpen(true);
										}
									}}
								/>
							</div>
							<div className="SwitchWrapper">
								<label
									style={{
										letterSpacing: '0.05em',
										fontSize: 10,
										marginRight: 20,
									}}
									htmlFor={'nft'}>
									NFT
								</label>
								<Switch
									id={'nft'}
									checked={type ==='nft'}
									disabled={shopify === 1 ? true : false}
									onChange={() => {										
										if(type ==='nft' && visibility === 'private'){
											setNftType('emojam')
										}
										else{
										setNftType('nft')
										setVisibility('private');
										}
									}
								}
								/>
							</div>
							<div className="SwitchWrapper">
								<label
									style={{
										letterSpacing: '0.05em',
										fontSize: 10,
										marginRight: 20,
									}}
									htmlFor={'Shopify'}>
									Monetize via Shopify
								</label>
								<Switch
									id={'shopify'}
									checked={shopify === 1}
									disabled={type === 'nft'? true : false}
									onChange={() => {				
										if(shopify === 1 && visibility === 'private'){				
											setShopify(0)
										}
										else{
										setShopify(1)
										setVisibility('private');
										}
									}
								}
								/>
							</div>
						</>
					</div>
					<div>
						<Input
							label={'title'}
							placeholder={'ex: Do Your Tequila Dance'}
							value={title}
							disabled={!!music.link}
							onChange={e => setTitle(e.currentTarget.value)}
							required={true}
						/>
					</div>
					<div>
						<Input
							label={'source name'}
							placeholder={'ex: The Fitness Marshall'}
							value={source}
							disabled={!!music.link}
							onChange={e => setSource(e.currentTarget.value)}
							required={true}
						/>
					</div>
					{!music.link || (
						<div>
							<Input
								label={'source link'}
								value={music.link}
								disabled={true}
							/>
						</div>
					)}
					<div>
						<InputTags tags={tags} setTags={setTags} />
					</div>

					<div className="ButtonsWrapper">
						<Button
							to={'/create/2'}
							size="ghost"
							style={{ color: 'black' }}
							disabled={uploading}>
							back
						</Button>
						<Button
							disabled={uploading || !title || !source}
							size="normal"
							onClick={() => {
								if (title && source) {
									setUploading(true);
									handleSubmit(
										title,
										source,
										tags,
										type,
										shopify,
										visibility										
									);
								}
							}}>
							{uploading ? 'uploading' : 'upload'}
						</Button>
					</div>
				</div>
			</MainRightLayout>
			<CustomModalYoutube
				title={'for YouTube members only'}
				text={
					'we’ve partnered with YouTube so you can offer exclusive Emojam audio GIFs to your paying members. Connect your YouTube account to see if you are eligible for this feature.'
				}
				className="Modal"
				overlayClassName="Overlay"
				isOpen={isOpen}
				handleClose={() => setIsOpen(false)}
				handleDelete={() => {
					history.push('/settings/youtube/link');
				}}
			/>
		</MainLayout>
	);
};
