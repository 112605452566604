import React, { FC, useState } from 'react';
import 'firebase/auth';
import { Button } from '../Shared/Button';
import * as firebase from 'firebase/app';
import { MainLayout, MainLeftLayout, MainRightLayout } from '../Layouts/Main';

import './Settings.style.scss';
import '../App/App.styles.scss';
import { Input } from '../Shared/Input';
import { Link, Route, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { pushMessage } from '../../actions/messages';
import { Password } from './Password';
import { Youtube } from './Youtube';
import classnames from 'classnames';
import { Delete } from './Delete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Shopify } from './Shopify';

export const Settings: FC = () => {
	const user = firebase.auth().currentUser as firebase.User;
	const [username, setUsername] = useState(user?.displayName);
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	const dispatch = useDispatch();

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (username && username !== user.displayName) {
			setLoading(true);
			user.updateProfile({
				displayName: username,
			})
				.then(() => {
					dispatch(pushMessage(`username updated`, 'success'));
				})
				.catch(e => {
					dispatch(pushMessage(e.message, 'error'));
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	return (
		<MainLayout>
			<MainLeftLayout>
				<ul className={'SettingsMenu'}>
					<li
						className={classnames({
							active: location.pathname === '/settings',
						})}>
						<Link to="/settings">account</Link>
					</li>
					<li
						className={classnames({
							active: location.pathname === '/settings/password',
						})}>
						<Link to="/settings/password">password</Link>
					</li>
					<li
						className={classnames({
							active:
								location.pathname === '/settings/youtube/link',
						})}>
						<Link to="/settings/youtube/link">
							emojam for YouTube Creators{' '}
						</Link>
					</li>
					<li
						className={classnames({
							active:
								location.pathname === '/settings/shopify',
						})}>
						<Link to="/settings/shopify">
							connect to Shopify{' '}
						</Link>
					</li>
					<li>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.emojam.com/faq">
							FAQ
						</a>
					</li>
					<li>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="http://www.emojam.com/guidelines">
							content creation best practices
						</a>
					</li>
					<li>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.emojam.com/terms">
							terms of use{' '}
						</a>
					</li>
					<li>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.emojam.com/community-guidelines">
							community guidelines
						</a>
					</li>
					<li>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.emojam.com/privacy">
							privacy policy
						</a>
					</li>
					<li>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="mailto:contact@emojam.com">
							contact us
						</a>
					</li>
				</ul>
			</MainLeftLayout>

			<MainRightLayout>
				<h2 style={{ marginTop: 12 }}>settings</h2>
				<Route exact={true} path="/settings">
					<form onSubmit={handleSubmit}>
						<div className="DashboardLeftDescription">
							<Input
								label={'username'}
								placeholder={'username'}
								value={username || ''}
								onChange={e =>
									setUsername(e.currentTarget.value)
								}
								required={true}
							/>
						</div>
						<div className="ButtonsWrapper">
							<Link to={'/settings/account/delete'}>
								<FontAwesomeIcon
									color={'black'}
									icon={faTrash}
									cursor={'pointer'}
								/>
							</Link>

							<Button
								size="ghost"
								style={{ color: 'black' }}
								onClick={() => {
									firebase.auth().signOut();
								}}>
								sign out
							</Button>
							<Button
								loading={loading}
								disabled={
									loading ||
									!username ||
									!(username !== user.displayName)
								}
								type="submit"
								size="normal">
								save
							</Button>
						</div>
					</form>
				</Route>
				<Route exact={true} path="/settings/password">
					<Password />
				</Route>
				<Route exact={true} path="/settings/youtube/link">
					<Youtube />
				</Route>
				<Route exact={true} path="/settings/shopify">
					<Shopify />
				</Route>
				<Route exact={true} path="/settings/account/delete">
					<Delete />
				</Route>
			</MainRightLayout>
		</MainLayout>
	);
};
